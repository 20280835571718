import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'
/* eslint-disable */

export const getProcessesAndLookupData = () => configApi.get('/api/Ropa/Processes').then(({ data }) => ({
	data: {
		lookUpData: {
			cassieSubSystemTypes: data.lookupData.cassieSubSystemTypes,
			contacts: data.lookupData.contacts?.map(contact => ({
				...contact,
				createdDate: format(new Date(contact.createdDate), DATE_FORMAT)
			})),
			countries: data.lookupData.countries,
			dataSubjectCategories: data.lookupData.dataSubjectCategories?.map(dataSubjectCategory => ({
				...dataSubjectCategory,
				createdDate: format(new Date(dataSubjectCategory.createdDate), DATE_FORMAT)
			})),
			dataTransferTypes: data.lookupData.dataTransferTypes,
			legalBasis: data.lookupData.legalBasis?.map(legal => ({
				...legal,
				createdDate: format(new Date(legal.createdDate), DATE_FORMAT)
			})),
			noneProcessAlerts: data.lookupData.noneProcessAlerts,
			organisationTypes: data.lookupData.organisationTypes,
			organisations: data.lookupData.organisations?.map(org => ({
				...org,
				createdDate: format(new Date(org.createdDate), DATE_FORMAT)
			})),
			piiCategories: data.lookupData.piiCategories?.map(pii => ({
				...pii,
				createdDate: format(new Date(pii.createdDate), DATE_FORMAT)
			})),
			processOwners: data.lookupData.processOwners?.map(processOwner => ({
				...processOwner,
				createdDate: format(new Date(processOwner.createdDate), DATE_FORMAT)
			})),
			processPurposes: data.lookupData.processPurposes?.map(processPurpose => ({
				...processPurpose,
				createdDate: format(new Date(processPurpose.createdDate), DATE_FORMAT)
			})),
			retentionPeriods: data.lookupData.retentionPeriods?.map(retentionPeriod => ({
				...retentionPeriod,
				createdDate: format(new Date(retentionPeriod.createdDate), DATE_FORMAT)
			})),
			scheduleTypes: data.lookupData.scheduleTypes,
			securityMeasureTypes: data.lookupData.securityMeasureTypes,
			specialPiiCategories: data.lookupData.specialPiiCategories?.map(spii => ({
				...spii,
				createdDate: format(new Date(spii.createdDate), DATE_FORMAT)
			})),
			systemTypes: data.lookupData.systemTypes,
			systems: data.lookupData.systems?.map(system => ({
				...system,
				createdDate: format(new Date(system.createdDate), DATE_FORMAT)
			})),
		},
		processes: data.processes?.map(process => ({
			...process,
			createdDate: format(new Date(process.createdDate), DATE_FORMAT),
			lastEditedDate: format(new Date(process.lastEditedDate), DATE_FORMAT),
			id: process.processId,
			internalContactId: process.internalContactID,
			scheduleTypeId: process.scheduleTypeID,
			securityMeasureTypeId: process.securityMeasureTypeID,
			sourceSystemId: process.sourceSystemData.sourceSystemId,
			sourceSystemName: process.sourceSystemData.sourceSystemName,
			sourceCassieSubSystemInstanceId: process.sourceSystemData.sourceCassieSubSystemInstanceId,
			destinationSystemId: process.destinationSystemData.destinationSystemId,
			destinationSystemName: process.destinationSystemData.destinationSystemName,
			destinationCassieSubSystemInstanceId: process.destinationSystemData.destinationCassieSubSystemInstanceId,
			dataSubjectCategories: process.dataSubjectCategories?.map(dataSubjectCategory => {
				return dataSubjectCategory.dataSubjectCategoryId
			}),
			piiCategories: process.piiCategories?.map(piiCategory => {
				return piiCategory.piiCategoryId
			}),
			specialPiiCategories: process.specialPiiCategories?.map(spiiCategory => {
				return spiiCategory.specialPiiCategoryId
			}),
			isPii: Boolean(process.piiCategories),
			isSpii: Boolean(process.specialPiiCategories)
		}))
	}
}))

export const deleteLookUpData = deletedLookupData => {
	return configApi.delete(`/api/Ropa/LookupData?LookupObject=${deletedLookupData.lookupObject}&Id=${deletedLookupData.id}`)
}

export const deleteProcess = processId => {
	return configApi.delete(`/api/Ropa/Processes/${processId}`)
}

export const upsertLookupData = lookupData => {
	configApi.post('/api/Ropa/LookupDataUpsert', lookupData)			
}

export const upsertProcess = process => {
	const ropaProcess = {
		id: process.id,
		brandId: process.brandId,
		name: process.name,
		processPurposeID: process.processPurposeId,
		description: process.description,
		cityId: process.cityId,
		processOwnerId: process.ownerId,
		dataTransferTypeId: process.dataTransferTypeId,
		isSpecialCategoryData: process.isSpecialCategoryData,
		isAutomatedDecisions: process.isAutomatedDecisions,
		legalBasisId: process.legalBasisId,
		legalBasisFileGUID: process.legalBasisFileGUID,
		isDPACompleted: process.isDPACompleted,
		dpaFileGUID: process.dpaFileGUID,
		isArticle30Required: process.isArticle30Required,
		article30FileGUID: process.article30FileGUID,
		dpoContactID: process.dpoContactId,
		internalContactID: process.internalContactId,
		specialCategoryProcessingDescription: process.specialCategoryProcessingDescription,
		sourceSystemID: process.sourceSystemId,
		sourceCassieSubSystemInstanceID: process.sourceCassieSubSystemInstanceId ? parseInt(process.sourceCassieSubSystemInstanceId) : null, 
		destinationSystemID: process.destinationSystemId,
		destinationCassieSubSystemInstanceID: process.destinationCassieSubSystemInstanceId ? parseInt(process.destinationCassieSubSystemInstanceId) : null,
		scheduleTypeID: process.scheduleTypeId,
		securityMeasureTypeID: process.securityMeasureTypeId
	}
	ropaProcess.processDataSubjectCategories = []
	ropaProcess.processPIICategories = []
	ropaProcess.processSpecialPIICategories = []
	process.dataSubjectCategories.forEach(category => {
		ropaProcess.processDataSubjectCategories.push({
			dscid: category
		})
	})
	process.piiCategories.forEach(category => {
		ropaProcess.processPIICategories.push({
			piicid: category
		})
	})
	process.specialPiiCategories.forEach(category => {
		ropaProcess.processSpecialPIICategories.push({
			spiicid: category
		})
	})

	configApi.post('/api/Ropa/ProcessUpsert', ropaProcess)
}

export const getProcessCounts = (processIds, dateFrom, dateTo) => {
	return configApi.get(`/api/Ropa/ProcessCounts?Processes=${processIds}&FromDate=${dateFrom}&ToDate=${dateTo}`)
}
export const getProcessCountsCumulative = (processIds, dateFrom, dateTo) => {
	return configApi.get(`/api/Ropa/ProcessCountsCumulative?Processes=${processIds}&FromDate=${dateFrom}&ToDate=${dateTo}`)
}

export const uploadRopaFile = ropaFile => configApi.post('/api/Ropa/UploadRopaFile', ropaFile)
