<template>
	<ProccesingLayout>
		<template #content>
			<div class="cassie-vertical-md mt-1">
				<FilterBar
					:search-query.sync="searchQuery"
					search-query-label="Process Name"
					hide-brand-filter
					@clearFilters="clearFilters"
				>
					<template #after-filters>
						<Dropdown
							v-model="selectedProcessPurpose"
							label="Process Purpose"
							:items="processPurposeItems"
							custom-sort
						/>
						<Dropdown
							v-model="selectedCrossBorderFilter"
							label="Cross Border"
							:items="yesNoFilterItems"
							custom-sort
						/>
						<Dropdown
							v-model="selectedPiiFilter"
							label="PII"
							:items="yesNoFilterItems"
							custom-sort
						/>
						<Dropdown
							v-model="selectedSpiiFilter"
							label="SPII"
							:items="yesNoFilterItems"
							custom-sort
						/>
						<Dropdown
							v-model="selectedActiveFilter"
							label="Active"
							:items="yesNoFilterItems"
							custom-sort
						/>
					</template>
				</FilterBar>
				<SectionCard>
					<template #title>
						Processes
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="filteredProcesses"
						>
							<template #item="row">
								<tr @click="onViewSecurityMatrix(row.item)">
									<td>
										{{ row.item.processId }}
									</td>
									<td>
										{{ row.item.name }}
									</td>
									<td>
										{{ row.item.processPurposeName }}
									</td>
									<td>
										{{ row.item.ownerName }}
									</td>
									<td
										:class="{
											'orange': row.item.isPii,
										}"
									>
										{{ row.item.isPii ? 'Yes' : 'No' }}
									</td>
									<td
										:class="{
											'orange': row.item.isSpii,
										}"
									>
										{{ row.item.isSpii ? 'Yes' : 'No' }}
									</td>
									<td>
										{{ row.item.scheduleTypeName }}
									</td>
									<td
										:class="{
											'yellow': row.item.isCrossBorder,
										}"
									>
										{{ row.item.isCrossBorder ? 'Yes' : 'No' }}
									</td>
									<td
										:class="{
											'red': row.item.isAlert,
										}"
									>
										{{ !row.item.isAlert ? 'Yes' : 'No' }}
									</td>
									<td>
										{{ row.item.sourceSystemName }}
									</td>
									<td>
										{{ row.item.destinationSystemName }}
									</td>
									<td>
										{{ format(new Date(row.item.createdDate), DATE_FORMAT) }}
									</td>
									<td>
										<IconButton
											tooltip-text="View Security Matrix"
											@click="onViewSecurityMatrix(row.item)"
										>
											mdi-information
										</IconButton>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</SectionCard>
			</div>
		</template>
	</ProccesingLayout>
</template>
<script>
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import ProccesingLayout from '../processing-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { getProcessesAndLookupData } from '../../../../../../shared/utils/api/processing.js'
import { PROCESSING_MANAGE_PROCESS } from '../../../../router/route-names.js'
import { getRandomColorByString } from '../../../../../../shared/utils/getRandomColorByString.js'
export default {
	components: { ProccesingLayout, SectionCard, DataTable, FilterBar, IconButton, Dropdown },
	data () {
		return {
			format,
			DATE_FORMAT,
			searchQuery: null,
			selectedProcessPurpose: null,
			selectedCrossBorderFilter: null,
			selectedPiiFilter: null,
			selectedSpiiFilter: null,
			selectedActiveFilter: null,
			processes: [],
			lookUpData: null
		}
	},
	computed: {
		filteredProcesses () {
			return this.processes.filter(({ name, processPurposeId, isCrossBorder, isPii, isSpii, isAlert }) => {
				let check = true
				if (this.searchQuery) check = name.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedProcessPurpose !== null) check = check && this.selectedProcessPurpose === processPurposeId
				if (this.selectedCrossBorderFilter !== null) check = check && this.selectedCrossBorderFilter === isCrossBorder
				if (this.selectedPiiFilter !== null) check = check && this.selectedPiiFilter === isPii
				if (this.selectedSpiiFilter !== null) check = check && this.selectedSpiiFilter === isSpii
				if (this.selectedActiveFilter !== null) check = check && this.selectedActiveFilter !== isAlert
				return check
			})
		},
		processPurposeItems () {
			const proccessPurposeDropdown = [
				{
					text: 'View All',
					value: null
				},
				...this.lookUpData.processPurposes?.map(({ processPurposeName, processPurposeId }) => ({
					text: processPurposeName,
					value: processPurposeId
				})).sort((a, b) => a.text.localeCompare(b.text))
			]
			return proccessPurposeDropdown
		},
		yesNoFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Yes',
					value: true
				},
				{
					text: 'No',
					value: false
				}
			]
		},
		tableHeaders () {
			return [
				{
					value: 'processId',
					text: 'ID'
				},
				{
					value: 'name',
					text: 'Process Name'
				},
				{
					value: 'processPurposeName',
					text: 'Purpose'
				},
				{
					value: 'ownerName',
					text: 'Owner'
				},
				{
					value: 'isPii',
					text: 'PII'
				},
				{
					value: 'isSpii',
					text: 'SPII'
				},
				{
					value: 'scheduleTypeName',
					text: 'Schedule'
				},
				{
					value: 'isCrossBorder',
					text: 'X Border'
				},
				{
					value: 'isActive',
					text: 'Active'
				},
				{
					value: 'sourceSystemName',
					text: 'Data Source'
				},
				{
					value: 'destinationSystemName',
					text: 'Destination'
				},
				{
					value: 'createdDate',
					text: 'Created Date'
				},
				{
					value: 'action',
					text: 'Action',
					sortable: false
				}
			]
		}
	},
	created () {
		this.loadProcessingData()
	},
	methods: {
		async loadProcessingData () {
			const { data: { processes, lookUpData } } = await getProcessesAndLookupData()
			this.processes = this.addPropHelpers(processes)
			this.lookUpData = lookUpData
		},
		onViewSecurityMatrix (processSecurityMatrix) {
			this.$router.push({
				name: PROCESSING_MANAGE_PROCESS,
				params: {
					processSecurityMatrix
				}
			})
		},
		addPropHelpers (processes) {
			return processes.map(process => ({
				processColor: getRandomColorByString(`${process.processId}${process.name}`),
				...process
			}))
		},
		clearFilters () {
			this.selectedActiveFilter = null
			this.selectedCrossBorderFilter = null
			this.selectedPiiFilter = null
			this.selectedProcessPurpose = null
			this.selectedSpiiFilter = null
		}
	}
}
</script>
